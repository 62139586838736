define("discourse/plugins/cablog-affiliate/discourse/connectors/category-custom-settings/cablog-affiliate", ["exports", "@glimmer/component", "@ember/modifier", "@ember/component", "@ember/helper", "discourse-common/helpers/d-icon", "@ember/object", "discourse/components/d-button", "@ember/service", "@glimmer/tracking", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "discourse-i18n", "discourse-common/helpers/html-safe", "@ember/template-factory"], function (_exports, _component, _modifier, _component2, _helper, _dIcon, _object, _dButton, _service, _tracking, _ajax, _ajaxError, _i18n, _discourseI18n, _htmlSafe, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TrustapTopicStatus extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <h3>{{i18n "cablog_affiliate.heading"}}</h3>
    
        <section class="field cablog-affiliate-exclude">
          <label for="cablog-affiliate-exclude">
            {{i18n "cablog_affiliate.cablog_affiliate_exclude.label"}}
          </label>
    
          <Input
            @value={{this.args.outletArgs.category.custom_fields.cablog_affiliate_exclude}}
            type="text"
          />
        </section>
      
    */
    {
      "id": "HqjaijIt",
      "block": "[[[1,\"\\n    \"],[10,\"h3\"],[12],[1,[28,[32,0],[\"cablog_affiliate.heading\"],null]],[13],[1,\"\\n\\n    \"],[10,\"section\"],[14,0,\"field cablog-affiliate-exclude\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"cablog-affiliate-exclude\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"cablog_affiliate.cablog_affiliate_exclude.label\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[32,1],[[24,4,\"text\"]],[[\"@value\"],[[30,0,[\"args\",\"outletArgs\",\"category\",\"custom_fields\",\"cablog_affiliate_exclude\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/cablog-affiliate/discourse/connectors/category-custom-settings/cablog-affiliate.js",
      "scope": () => [_i18n.default, _component2.Input],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TrustapTopicStatus;
});